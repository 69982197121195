<template>
  <b-card>
    <pocu-header></pocu-header>
    <div v-if="currentProject">
      <div id="terms_not_accepted" v-if="!licenseKey">
        <b-row>
          <b-col style="max-width:1000px;margin-bottom:20px;" cols="12">
            <p>Mulțumim că utilizezi POCU<strong>manager</strong></p>
          </b-col>
        </b-row>
        <b-row>
          <b-col style="max-width:1000px;margin-bottom:20px;" cols="12">
            <p>Funcția de "Export execuție bugetară" este disponibilă pentru proiectele care dețin licență de utilizare.<br/>
            Achitând licența de utilizare ajută la susținerea dezvoltării de noi module și funcționalități. Licența se emite pentru fiecare proiect interogat și nu poate fi utilizată pentru mai multe proiecte. Prețul licenței este unul dinamic (se va modifica în funcție de module).</p>
          </b-col>
        </b-row>
        <b-row>
          <b-col style="text-align:center;max-width:1000px;" cols="12">
            <p><strong>La acest moment, costul licenței de utilizare pentru un proiect POCU este de:</strong></p>
            <p class="price_value">{{licensePrice}} lei (fără TVA)</p>
            <p>Licența de utilizare este valabilă pe termen nelimitat.</p>
            <p>Licența îți permite utilizarea POCU<strong>manager</strong> - <span class="primary_color">inclusiv a funcțiilor dezvoltate după plata licenței</span> și până la {{licenseValidUntil}}</p>
            <b-row>
            <b-col style="text-align:center;margin:auto;max-width:350px;margin-bottom:50px;margin-top:50px;" cols="12">
              <b-button size="sm" variant="primary" @click="acceptTerms" block>
                sunt de acord*
              </b-button>
            </b-col>
          </b-row>
          </b-col>
        </b-row>
        <b-row>
          <b-col style="max-width:1000px;" cols="12">
            <p>*prin apăsarea butonului <strong>"sunt de acord"</strong> îți va fi alocat automat un cod de licență, și poți utiliza funcția de export. Ulterior, vei fi contactat/ă de un reprezentant SMIS.ro și ne vei transmite datele de facturare/contractare și alte detalii. Mulțumim că ai ales să susții acest proiect!</p>
          </b-col>
        </b-row>
      </div>
      <div v-else>
        <b-row>
          <b-col>
            <p class="license_number">{{licenseKey}}</p>
          </b-col>
        </b-row>
        <b-row>
          <b-col style="max-width:1000px;">
            <p>Descarcă fișierul export pentru <strong>proiectul POCU {{currentProject.id_proiect}}</strong></p>
            <p><i>{{currentProject.nume}}</i></p>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-row>
              <b-col cols="12" sm="6" md="6" lg="6" xl="2" style="max-width:250px;">
                <b-button size="sm" variant="primary" @click="downloadExcel" block>
                  Descarcă buget
                </b-button>
              </b-col>
              <!--<b-col cols="12" sm="6" md="6" lg="6" xl="2" style="max-width:250px;">
                <b-button disabled size="sm" variant="danger" @click="downloadPrezentare" block>
                  Descarcă prezentare
                </b-button>
              </b-col> -->
            </b-row>
          </b-col>
        </b-row>
      </div>
    </div>
    <div v-else>
      <div id="no_content">
        <p>Nu ai nici un proiect. Poți adăuga un proiect nou folosind butonul de mai sus.</p>
      </div>
    </div>
  </b-card>
</template>

<script>
import axios from '@axios'
import {
  BRow, BCol, BCard, BTable, BAvatar, BBadge, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton, BCardBody, BFormCheckboxGroup, BSpinner
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import XLSX from 'xlsx'
import PocuHeader from './parts/PocuHeader.vue'
import {mapState} from 'vuex'

export default {
  components : {
    BCard,
    BRow,
    BCol,
    BButton,
    PocuHeader,
  },
  data() {
    return {
      licensePrice : 480,
      licenseValidUntil: '31.05.2021',
    }
  },
  computed: {
    ...mapState('pocu',{
      currentProjectId : 'project_id',
      allProjects: 'projects'
    }),
    currentProject(){
      return this.allProjects[this.currentProjectId]
    },
    licenseKey(){
      return this.currentProject ? this.currentProject.license_key : ''
    },
  },
  watch: {
    currentProjectId(newVal,oldVal){

    }
  },
  methods: {
    maybeShowDecimals(value){
      if(Math.round(value) !== +value) {
        return +value
      }
      return Math.round(value)
    },
    updateProject(project){
      this.$store.commit('pocu/UPDATE_PROJECT',project)
    },
    acceptTerms(){
      //Generate and set a license key
      axios
        .get('/pocu/generateProjectLicenseKey',{
          params: {
            project_id: this.currentProject.id
          }
        })
        .then(response => {
          if (response.data.success){
            this.updateProject(response.data.project)
          }
        })
    },
    getLiniiBugetare(cb){
      axios
      .get('/pocu/getLiniiBugetare',{
        params: {
          id_proiect: this.currentProjectId
        }
      })
      .then(response => cb(response))
    },
    downloadExcel(){
      this.buildExcelExport(json => {
        let sheet = XLSX.utils.json_to_sheet(json,{
          skipHeader:true
        })
        sheet['!autofilter'] = {ref:"A1:AG" + json.length}
        let workbook = XLSX.utils.book_new()

        XLSX.utils.book_append_sheet(workbook,sheet,'POCU ' + this.currentProject.id_proiect)

        XLSX.writeFile(workbook,'Buget POCU '  + this.currentProject.id_proiect + '.xlsx')
        //Send the file to server, to send email
        let formData = new FormData()
        const config = {
          headers: { 'content-type': 'multipart/form-data'}
        }
        formData.append('project_id',this.currentProject.id)
        formData.append('generated_excel',new File([XLSX.write(workbook,{bookType:'xlsx',type:'array'})],'Buget POCU '  + this.currentProject.id_proiect + '.xlsx'))
        const $this = this
        axios.post('/pocu/sendEmailAfterBugetExcelDownload', formData, config)
          .then(function(response){

          })
          .catch(function(error){
            // console.log(error)
          })
      })
    },
    buildExcelExport(cb){
      let headers = {
        'excel_line' : 'Nr.crt.',
        'componenta' : 'Componenta',
        'companie' : 'Companie',
        'activitate' : 'Activitate',
        'subactivitate' : 'Subactivitate',
        'categorie' : 'Categorie',
        'cheltuiala' : 'Cheltuială',
        'tip_cheltuială' : 'Tip cheltuială',
        'achizitia' : 'Achiziția',
        'id_linie_bugetara' : 'Id linie bugetară',
        'produs' : 'Produs/Serviciu',
        'um' : 'U.M.',
        'cantitate' : 'Cantitate',
        'pret_unitar_fara_tva' : 'Preţ unitar (fără TVA) [LEI]',
        'total_cheltuieli' : 'Valoare totală fără TVA',
        'procent_valoare_tva' : 'Procent valoare TVA',
        'valoarea_tva' : 'Procent valoare TVA',
        'cheltuieli_eligibile_fara_tva' : 'Cheltuieli eligibile fără TVA',
        'cheltuieli_neeligibile_fara_tva' : 'Cheltuieli neeligibile fără TVA',
        'tva_eligibil' : 'TVA eligibil',
        'tva_neeligibil' : 'TVA neeligibil',
        'total_cheltuieli_cu_tva' : 'Total cheltuieli eligibile',
        'public' : 'Public [LEI]',
        'contributie_proprie' : 'Contribuţie proprie eligibilă',
        'finantare_nerambursabila' : 'Nerambursabil',
        'ajutor_de_stat' : 'Ajutor de stat',
        'subcategorie_ajutor_de_stat' : 'Subcategorie',
        'furnizat' : 'Furnizat',
        'cofinantare_yei' : 'Cofinanțare YEI',
        'referinta_document_justificativ' : 'Referință document justificativ',
        'justificare' : 'Justificare',
        'cheltuieli_solicitate' : 'Cheltuieli Solicitate',
        'buget_disponibil' : 'Buget Disponibil',
      };
      let json = [];
      //Headers
      json.push(Object.values(headers))
      //Get Data
      this.getLiniiBugetare(response => {
        if (response.data.success){
          // let parteneri = []
          // let parteneriGasiti = []
          // let activitatiGasite = []
          // let subactivitatiGasite = []
          // let categoriiGasite = []
          // let items = response.data.items.map(item => {
          //   if (!parteneriGasiti.includes(item.nume_partener)){
          //     parteneriGasiti.push(item.nume_partener)
          //     parteneri.push({
          //       text : item.nume_partener.substring(0,30) + (item.nume_partener.length > 30 ? "..." : ""),
          //       value : item.nume_partener,
          //     })
          //   }
          //   if (!activitatiGasite.includes(item.activitate)){
          //     activitatiGasite.push(item.activitate)
          //   }
          //   if (!subactivitatiGasite.includes(item.subactivitate)){
          //     subactivitatiGasite.push(item.subactivitate)
          //   }
          //   if (!categoriiGasite.includes(item.categorie)){
          //     categoriiGasite.push(item.categorie)
          //   }
          //   return {
          //       index : parseInt(item.excel_line),
          //       partener: {
          //         text : item.nume_partener.substring(0,30) + (item.nume_partener.length > 30 ? "..." : ""),
          //         // text: item.nume_partener,
          //         nume: item.nume_partener,
          //       },
          //       id_linie_bugetara: item.id_linie_bugetara,
          //       activitate: {
          //         text: item.activitate.substring(0,30) + (item.activitate.length > 30 ? "..." : ""),
          //         nume: item.activitate,
          //       },
          //       subactivitate: {
          //         text: item.subactivitate.substring(0,30) + (item.subactivitate.length > 30 ? "..." : ""),
          //         // text: item.subactivitate,
          //         nume: item.subactivitate,
          //       },
          //       categorie: {
          //         text: item.categorie.substring(0,30) + (item.categorie.length > 30 ? "..." : ""),
          //         // text: item.categorie,
          //         nume: item.categorie,
          //       },
          //       cheltuiala: {
          //         text: item.cheltuiala.substring(0,30) + (item.cheltuiala.length > 30 ? "..." : ""),
          //         nume: item.cheltuiala,
          //       },
          //       produs: {
          //         text: item.produs.substring(0,30) + (item.produs.length > 30 ? "..." : ""),
          //         nume: item.produs,
          //       },
          //       cheltuiala_totala: this.maybeShowDecimals(parseFloat(item.total_cheltuieli_cu_tva).toFixed(2)),
          //       // contributie_proprie: parseFloat(item.contributie_proprie).toFixed(2),
          //       // tip_cheltuiala: item.tip_cheltuiala
          //       cheltuieli_solicitate: this.maybeShowDecimals(parseFloat(item.cheltuieli_solicitate).toFixed(2)),
          //       cheltuieli_neeligibile: this.maybeShowDecimals(parseFloat(item.cheltuieli_neeligibile).toFixed(2)),
          //       cheltuieli_autorizate: this.maybeShowDecimals(parseFloat(item.cheltuieli_autorizate).toFixed(2)),
          //       buget_disponibil: {
          //         valoare : this.maybeShowDecimals(parseFloat(item.buget_disponibil).toFixed(2)),
          //         procentaj : item.buget_disponibil != 0 ? this.maybeShowDecimals(parseFloat(item.buget_disponibil/(item.total_cheltuieli_cu_tva/100)).toFixed(2)) : 0
          //       }
          //   }
          // })
          // parteneri.sort()
          let rawData = response.data.items
          rawData = rawData.sort(function(a,b){
            return +a.excel_line - +b.excel_line
          })
          // let rawDataJson = JSON.stringify(rawData)
          for (let line of rawData){
            let newLine = []
            for (let h in headers){
              if (!isNaN(line[h])){
                newLine.push(+line[h])
              } else {
                newLine.push(line[h])
              }
            }
            json.push(newLine)
          }
        }
        // console.log(response)

        cb(json)
        // return json
      })
    },
    downloadPrezentare(){

    }
  },
  mounted(){

  },
  created(){

  },
}
</script>

<style scoped>
  .license_number{
    font-size: 30px;
    font-weight: 700;
    color: #3ceed4;
  }
  .price_value{
    font-size: 30px;
    font-weight: 700;
    color: #3ceed4;
  }
  .valid_until{
    font-size: 30px;
    font-weight:300;
    color: #3ceed4;
  }
  .primary_color{
    font-weight:700;
    color: #3ceed4;
  }
  #no_content{
    text-align:center;
  }
</style>
